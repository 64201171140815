<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Field Options">
              <el-select
                placeholder="Enter options"
                v-model="field.options"
                :clearable="true"
                :allow-create="true"
                :default-first-option="true"
                :filterable="true"
                :multiple="true"
                :no-data-text="''"
              >
                <el-option
                  v-for="(opt, index) of field.options"
                  :key="index"
                  :label="opt"
                  :value="opt"
                >
                </el-option>
              </el-select>
              <i
                class="el-icon-edit"
                @click="openOptions"
                style="margin-left: 5px"
              ></i>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="form-group">
            <el-form-item>
              <el-switch
                v-model="field.view_type"
                active-text="Vertical View"
                inactive-text="Horizontal View"
              >
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="8">
          <div class="form-group">
            <el-form-item>
              <el-switch
                v-model="field.view_type"
                active-text="Vertical View"
                inactive-text="Horizontal View"
              >
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <is-field-required :field="field" class="field-required" />
    </el-form>
    <div>
      <dialog-component
        :visible="optionsDialogVisible"
        :title="'Edit options'"
        :destroy-on-close="true"
        :containerWidth="'35%'"
        :containerHeight="'55%'"
        @before-close="optionsDialogVisibleClose"
        center
        class="edit-options-popup"
      >
        <div class="tableScroll">
          <el-table
            border
            :data="dialogOptions"
            @cell-mouse-enter="editoptionsData"
            @cell-mouse-leave="resetoptionsData"
          >
            <el-table-column label="Options">
              <template slot-scope="scope">
                <div v-if="currentActiveRowIndex == scope.$index">
                  <el-input
                    size="mini"
                    v-model="dialogOptions[currentActiveRowIndex]"
                  ></el-input>
                </div>
                <div v-else>
                  <p style="font-size: 14px" slot="reference">
                    {{ scope.row }}
                  </p>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer">
          <el-button
            type="primary"
            @click="saveData"
            >Save</el-button
          >
        </span>
      </dialog-component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MultiSelect",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
  },
  props: ["field"],
  data() {
    return {
      currentActiveRowIndex: -1,
      optionsDialogVisible: false,
      newOption: "",
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      dialogOptions: [],
    };
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  mounted() {
    this.fetchGlobalVaribales();
  },
  methods: {
    optionsDialogVisibleClose(){
      this.optionsDialogVisible=false;
    },
    openOptions() {
      if (this.isView) {
        return;
      }
      this.optionsDialogVisible = true;
    },
    addOption() {
      if (this.newOption.trim() !== "") {
        this.field.options.push(this.newOption.trim());
        this.newOption = "";
      }
    },
    deleteOption(index) {
      // Delete option logic here
      this.field.options.splice(index, 1);
    },
    handleIndexChange(index) {
      console.log(index, "this is index xxxxxxxxxxxxx");
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: this.field.input_type,
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
    editoptionsData(row) {
      this.currentActiveRowIndex = this.field.options.indexOf(row);
    },
    resetoptionsData() {
      const uniqueOptions = [
        ...new Set(this.dialogOptions.map((option) => option.trim())),
      ];
      if (uniqueOptions.length < this.dialogOptions.length) {
        this.dialogOptions = uniqueOptions;
      }
      this.field.options = this.dialogOptions.slice();
      this.currentActiveRowIndex = -1;
    },
    saveData() {
      this.optionsDialogVisible = false;
    },
  },
  watch: {
    optionsDialogVisible(newVal) {
      if (newVal) {
        this.dialogOptions = this.field.options.slice();
      }
    },
  },
};
</script>

<style lang="scss">
.el-select .el-select__tags {
  display: flex;
  overflow-x: hidden;
  flex-wrap: nowrap;
}
</style>
